/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    h3: "h3",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "typescript",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#typescript",
    "aria-label": "typescript permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "TypeScript"), "\n", React.createElement(_components.p, null, "Use xstyled with TypeScript."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.p, null, "xstyled is written in TypeScript, all the library is strongly typed. It works out of the box for TypeScript users."), "\n", React.createElement(_components.p, null, "You may have to configure styled-components or Emotion, especially if you use a theme."), "\n", React.createElement(_components.h2, {
    id: "use-typescript-with-styled-components",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#use-typescript-with-styled-components",
    "aria-label": "use typescript with styled components permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Use TypeScript with Styled Components"), "\n", React.createElement(_components.h3, {
    id: "create-a-declaration-file",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#create-a-declaration-file",
    "aria-label": "create a declaration file permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Create a declaration file"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// styled.d.ts\n\n// import original module declarations\nimport 'styled-components'\nimport '@xstyled/system'\nimport {\n  ITheme,\n  DefaultTheme as XStyledDefaultTheme,\n} from '@xstyled/styled-components'\n\ninterface AppTheme extends ITheme, XStyledDefaultTheme {\n  /* Customize your theme */\n}\n\n// and extend them!\ndeclare module '@xstyled/system' {\n  export interface Theme extends AppTheme {}\n}\ndeclare module 'styled-components' {\n  export interface DefaultTheme extends AppTheme {}\n}\n")), "\n", React.createElement(_components.p, null, "Read ", React.createElement(_components.a, {
    href: "https://styled-components.com/docs/api#typescript"
  }, "styled-components TypeScript guide"), " to learn more about it."), "\n", React.createElement(_components.h2, {
    id: "use-typescript-with-emotion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#use-typescript-with-emotion",
    "aria-label": "use typescript with emotion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Use TypeScript with Emotion"), "\n", React.createElement(_components.h3, {
    id: "create-a-declaration-file-1",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#create-a-declaration-file-1",
    "aria-label": "create a declaration file 1 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Create a declaration file"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "// styled.d.ts\n\n// import original module declarations\nimport '@xstyled/system'\nimport '@emotion/react'\nimport { ITheme, DefaultTheme as XStyledDefaultTheme } from '@xstyled/emotion'\n\ninterface AppTheme extends ITheme, XStyledDefaultTheme {\n  /* Customize your theme */\n}\n\n// and extend them!\ndeclare module '@xstyled/system' {\n  export interface Theme extends AppTheme {}\n}\ndeclare module '@emotion/react' {\n  export interface Theme extends XStyledDefaultTheme {\n    /* Customize your theme */\n  }\n}\n")), "\n", React.createElement(_components.p, null, "Read ", React.createElement(_components.a, {
    href: "https://emotion.sh/docs/typescript"
  }, "Emotion TypeScript guide"), " to learn more about it."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
